import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import StructuredData from 'react-google-structured-data';
import logo from '../images/logo.svg';
import { SRLWrapper } from "simple-react-lightbox";


//options for image lightbox
const options = {
  settings: {

  },
  buttons: {
    showDownloadButton: false,
    showFullscreenButton: false,
    showAutoplayButton: false,
    disablePanzoom: false,
    disableWheelControls: false,
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: true,
  }
};


const Photos = ({ data }) => {



  const { title, description, photos, createdAt, updatedAt } = data.contentfulPhotos;

  const headerTitle = data.contentfulPageHeader.title;
  const headerDesc = data.contentfulPageHeader.body.body;
  const headerImage = data.contentfulPageHeader.heroImage.fluid.src;

  return (

    <Layout>
      <SEO title={headerTitle} pageDescription={headerDesc} heroImage={headerImage} keywords={[`gatsby`, `application`, `react`]} />
      <div className="container photo">
        <div className="row">
          <div className="col">
            <h1>{title}</h1>
            <div className="body-text"
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="container photo-grid">
        <div className="row">
          <div className="col">
            <div className="parent">
            <SRLWrapper options={options}>

            {photos.map((item, index) => {
              
                if(index %3 === 0){
                  return(<a href={item.fluid.src} data-attribute="SRL"><img src={item.horizontal.src} alt={item.title} className="img-fluid"/></a>);
                }
                if(index %2 === 0){
                  return(<a href={item.fluid.src} data-attribute="SRL"><img src={item.fluid.src} alt={item.title} className="img-fluid"/></a>);
                }
                else{
                  return(<a href={item.fluid.src} data-attribute="SRL"><img src={item.vertical.src} alt={item.title} className="img-fluid"/></a>);
                }

            })}
            </SRLWrapper>

          </div>
          </div>
        </div>
      </div>

      <StructuredData
        type='Organization'
        data={{
          url: 'https://example.com',
          logo: logo,
          name: 'jabercrombia',
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://google.com/article"
          },
          "image": "heroImage.file.url",
          "headline": title,
          sameAs: [
            'https://www.facebook.com/example/',
            'https://www.linkedin.com/company/example/',
          ],
          "datePublished": createdAt,
          "dateModified": updatedAt,
          "author": {
            "@type": "Person",
            "name": "Justin Abercrombia"
          },
        }}
      />

    </Layout>
  );
};
export default Photos;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPhotos(slug: {eq: $slug}) {
      title
      slug
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      pageDescription:description {
        description
      }
      createdAt(formatString: "MMMM Do, YYYY")
      updatedAt(formatString: "MMMM Do, YYYY")
      photos {
        title
        file{
          url
        }
        fluid(maxWidth: 2000, quality: 100) {
          src
        }
        vertical:fixed(width: 500, height: 700, quality: 100) {
          src
        }
        horizontal:fixed(width: 600, height: 400, quality: 100) {
          src
        }
        square:fixed(width: 270, height: 270, quality: 100) {
          src
        }
      }
  
    }
    contentfulPageHeader(title: {eq: "photos"}) {
      title
      body{
        body
      }
      heroImage {
        file {
          url
        }
        fluid(maxWidth: 2000, quality: 100) {
          src
        }
      }
    }
  }



`;